import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3264.000000 3264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,3264.000000) scale(0.100000,-0.100000)">

<path d="M10602 23808 c-69 -9 -111 -34 -133 -77 -11 -20 -14 -100 -15 -366
-1 -187 -3 -416 -4 -510 -3 -191 5 -224 72 -276 83 -65 118 -72 278 -59 145
12 328 0 480 -32 140 -29 162 -37 195 -75 17 -19 46 -39 65 -46 44 -15 81 -43
106 -82 10 -16 31 -40 46 -53 15 -13 37 -43 47 -68 11 -24 32 -56 47 -71 39
-38 71 -117 90 -214 9 -46 22 -111 29 -144 9 -44 10 -81 4 -140 -22 -181 -22
-186 -8 -212 17 -32 68 -64 135 -86 42 -14 62 -14 120 -5 44 7 129 8 234 3 91
-3 179 -5 195 -4 41 4 3551 4 3650 1 44 -2 195 0 336 5 203 6 260 11 275 23
116 91 121 98 132 174 23 155 52 236 86 236 12 0 56 44 56 55 0 27 128 173
175 201 24 13 54 39 68 57 17 22 45 40 89 56 36 13 83 41 105 61 35 31 58 41
154 64 120 30 204 59 229 80 20 17 155 44 260 52 101 7 271 40 320 61 65 29
205 43 555 53 523 16 1003 -6 1086 -50 34 -19 205 -50 327 -60 121 -11 238
-35 262 -56 35 -30 127 -64 172 -64 26 0 70 -9 98 -20 28 -11 61 -20 73 -20
12 0 37 -14 55 -31 35 -32 174 -89 218 -89 13 0 41 -16 62 -35 21 -19 49 -39
63 -45 51 -22 135 -76 169 -108 19 -18 46 -36 60 -39 37 -9 85 -43 106 -75 11
-16 34 -39 53 -51 116 -74 126 -81 138 -97 6 -9 49 -54 95 -102 139 -144 187
-201 214 -255 14 -29 32 -53 39 -53 19 0 55 -48 55 -73 0 -33 53 -140 86 -174
17 -18 35 -45 38 -60 25 -112 52 -186 79 -215 33 -36 57 -130 76 -293 11 -102
34 -198 54 -229 10 -14 18 -90 27 -239 11 -180 11 -258 1 -445 -9 -150 -18
-236 -27 -252 -16 -30 -54 -186 -54 -226 -1 -46 -47 -276 -57 -283 -30 -21
-81 -146 -98 -244 -4 -20 -23 -57 -42 -82 -35 -46 -83 -140 -83 -163 0 -14
-24 -54 -56 -94 -12 -15 -40 -65 -63 -112 -22 -47 -46 -86 -52 -86 -18 0 -75
-80 -95 -132 -3 -10 -14 -20 -23 -23 -9 -3 -36 -32 -61 -64 -25 -32 -61 -69
-80 -82 -19 -13 -50 -42 -70 -63 -19 -22 -45 -49 -57 -60 -13 -11 -23 -24 -23
-29 0 -13 -38 -43 -96 -77 -28 -17 -56 -41 -64 -54 -7 -14 -39 -39 -72 -56
-38 -21 -67 -45 -80 -66 -11 -19 -27 -34 -35 -34 -19 0 -98 -45 -148 -85 -59
-47 -107 -75 -131 -75 -12 0 -40 -15 -64 -32 -87 -67 -107 -77 -185 -104 -44
-15 -96 -40 -115 -55 -20 -16 -74 -43 -120 -60 -47 -17 -92 -37 -101 -45 -8
-8 -19 -14 -23 -14 -4 0 -16 -9 -28 -20 -12 -11 -29 -20 -38 -20 -8 0 -59 -11
-112 -25 -103 -26 -184 -58 -190 -75 -3 -11 -30 -17 -278 -69 -90 -19 -155
-39 -195 -60 -52 -27 -88 -35 -255 -59 -183 -27 -277 -53 -295 -81 -3 -5 -29
-12 -58 -15 -28 -3 -146 -15 -262 -26 -198 -20 -422 -58 -483 -81 -15 -6 -90
-15 -167 -20 -77 -5 -185 -13 -240 -19 -55 -6 -221 -15 -370 -20 -380 -13
-712 -38 -759 -56 -21 -8 -86 -24 -143 -35 -109 -22 -145 -38 -226 -101 -27
-21 -53 -38 -57 -38 -19 0 -87 -54 -128 -100 -23 -27 -51 -52 -62 -55 -18 -6
-80 -86 -80 -104 0 -4 -14 -20 -31 -37 -46 -43 -76 -95 -87 -150 -16 -79 -44
-168 -59 -185 -7 -9 -21 -40 -30 -70 -26 -80 -25 -378 1 -466 10 -35 24 -67
31 -73 7 -6 25 -53 41 -106 21 -70 38 -105 63 -132 18 -20 54 -71 80 -113 34
-57 64 -91 119 -134 39 -32 72 -62 72 -66 0 -5 4 -9 9 -9 5 0 23 -14 41 -30
18 -17 44 -33 59 -36 14 -4 41 -22 59 -41 17 -19 53 -43 79 -55 25 -11 75 -49
111 -84 36 -35 70 -64 75 -64 21 0 107 -44 107 -54 1 -23 64 -80 102 -92 10
-4 28 -23 39 -43 27 -44 59 -77 85 -85 11 -3 28 -21 39 -38 21 -36 32 -47 88
-84 20 -13 37 -31 37 -39 0 -15 87 -105 101 -105 5 0 16 -14 24 -31 9 -17 40
-56 68 -88 136 -150 161 -181 205 -255 27 -44 58 -84 74 -92 16 -8 39 -38 58
-76 17 -34 46 -76 63 -92 18 -17 46 -58 62 -90 17 -33 51 -83 78 -112 26 -28
47 -59 47 -68 0 -31 40 -102 85 -150 24 -27 47 -57 50 -68 4 -10 10 -18 15
-18 4 0 11 -11 14 -25 3 -13 19 -31 34 -39 39 -22 68 -57 76 -92 7 -33 30 -59
68 -78 12 -6 31 -30 43 -54 12 -24 42 -62 66 -85 24 -24 57 -60 73 -81 15 -21
40 -42 55 -47 16 -6 33 -23 43 -47 9 -20 24 -45 35 -54 29 -27 84 -81 152
-153 81 -84 143 -135 165 -135 9 0 16 -6 16 -13 0 -17 62 -73 108 -96 20 -11
46 -33 56 -49 11 -16 47 -43 84 -62 36 -18 82 -48 102 -66 20 -19 66 -48 101
-65 35 -17 78 -44 94 -59 30 -27 49 -39 140 -81 27 -13 71 -41 98 -61 27 -21
57 -38 66 -38 9 0 36 -9 59 -20 22 -11 49 -20 59 -20 9 0 43 -18 74 -40 32
-22 64 -40 72 -40 8 0 31 -7 51 -15 19 -8 58 -20 86 -26 27 -6 66 -22 87 -36
44 -30 153 -73 186 -73 40 0 178 -39 205 -58 38 -26 129 -51 219 -62 175 -20
300 -42 333 -60 57 -29 160 -50 297 -60 70 -5 155 -14 188 -20 33 -5 130 -14
216 -19 105 -7 192 -19 265 -37 111 -26 137 -29 634 -54 308 -15 1490 -32
2000 -28 493 3 490 3 533 51 44 50 47 94 47 606 l0 486 -32 50 c-23 33 -50 58
-86 78 l-53 28 -347 3 c-359 2 -523 14 -673 47 -48 10 -104 19 -126 19 -24 0
-50 8 -64 19 -43 33 -91 53 -203 83 -75 20 -121 39 -146 58 -20 16 -74 42
-119 59 -63 23 -93 41 -127 75 -41 42 -118 86 -150 86 -8 0 -26 18 -40 40 -24
36 -70 72 -116 92 -10 4 -18 12 -18 17 0 6 -21 22 -47 37 -27 15 -57 36 -68
46 -67 62 -134 121 -160 139 -16 12 -41 37 -55 56 -14 19 -38 47 -55 61 -66
59 -428 437 -433 452 -2 6 -10 10 -18 10 -8 0 -14 3 -14 8 0 10 -54 75 -121
145 -32 32 -62 71 -69 87 -7 17 -18 30 -25 30 -22 0 -56 34 -84 85 -17 31 -40
55 -62 67 -25 13 -42 33 -58 68 -16 36 -34 57 -62 73 -21 12 -44 35 -50 51
-16 36 -45 65 -85 82 -19 8 -36 26 -44 45 -16 38 -51 75 -80 84 -11 4 -32 23
-45 43 -35 54 -47 66 -76 79 -15 6 -39 29 -55 51 -16 22 -51 51 -80 66 -37 19
-56 37 -63 57 -6 18 -27 39 -57 56 -51 31 -60 39 -88 78 -11 14 -34 33 -50 41
-66 31 -95 51 -145 93 -28 23 -78 56 -111 72 -33 17 -60 34 -60 39 0 13 -92
55 -182 82 -45 13 -78 29 -78 36 0 19 85 52 175 68 44 7 112 22 150 33 75 21
171 35 360 51 253 21 334 36 445 80 55 22 200 47 340 59 97 9 245 46 273 69
14 12 208 52 247 52 37 0 201 48 220 64 25 22 121 53 185 60 88 10 135 26 180
62 33 26 52 33 110 39 89 10 184 43 243 86 26 18 70 39 97 45 110 27 142 37
190 62 28 14 55 31 60 37 14 16 109 55 134 55 38 0 126 41 152 71 17 20 43 34
80 44 97 25 130 39 172 78 23 20 48 37 57 37 37 0 180 85 180 106 0 8 8 14 18
14 9 0 26 7 37 16 11 8 36 19 57 25 21 5 52 25 70 43 18 18 68 50 111 71 51
25 88 51 107 75 17 21 41 41 55 44 43 11 94 47 115 82 12 19 30 34 39 34 10 0
30 9 44 20 14 11 28 20 31 20 3 0 21 16 40 35 20 19 38 35 41 35 10 0 125 92
125 100 0 13 92 100 107 100 6 0 25 16 40 35 15 19 46 48 68 64 22 16 56 52
75 80 19 28 39 51 43 51 10 0 67 58 67 68 0 4 26 35 58 68 31 34 73 83 92 110
19 27 55 70 80 95 25 25 55 67 68 92 12 25 28 47 36 50 7 3 24 28 38 55 35 70
66 119 99 155 16 17 29 36 29 42 1 5 9 28 20 50 11 22 19 47 20 56 0 9 16 40
35 68 37 54 85 151 85 173 0 17 47 118 64 138 18 21 51 119 77 230 12 52 27
102 34 110 24 31 55 134 65 218 6 48 15 105 20 127 4 22 11 78 15 125 3 47 12
108 20 135 46 168 50 224 50 655 0 431 -4 478 -46 611 -9 31 -20 94 -24 140
-4 46 -13 111 -20 144 -7 33 -16 87 -20 120 -7 61 -37 134 -61 148 -7 4 -16
24 -19 45 -25 150 -66 279 -95 297 -16 10 -47 76 -54 116 -9 50 -49 128 -85
167 -17 18 -34 45 -37 60 -11 52 -26 81 -64 122 -21 23 -51 67 -66 98 -15 32
-51 81 -80 110 -29 29 -55 65 -59 79 -3 14 -28 46 -55 72 -26 25 -55 61 -64
79 -11 21 -33 40 -61 54 -30 15 -48 31 -54 50 -13 38 -156 184 -203 209 -21
11 -38 23 -38 28 0 19 -81 89 -109 94 -21 4 -36 17 -47 39 -20 38 -37 53 -79
73 -28 12 -47 29 -100 85 -24 26 -114 70 -142 70 -11 0 -34 18 -52 40 -17 22
-36 40 -42 40 -23 0 -107 47 -137 77 -34 34 -126 83 -156 83 -23 0 -69 24 -87
46 -23 28 -79 59 -162 88 -42 15 -80 32 -83 38 -8 12 -131 69 -198 92 -27 9
-60 26 -74 36 -13 11 -29 20 -35 20 -6 0 -18 6 -26 14 -14 11 -115 39 -228 61
-18 4 -33 10 -33 14 0 5 -17 20 -37 33 -36 24 -72 34 -254 73 -44 9 -91 26
-109 40 -48 35 -141 63 -250 75 -107 12 -154 25 -188 51 -26 20 -145 48 -332
79 -160 26 -188 33 -289 69 -59 21 -112 31 -180 36 -53 3 -121 11 -151 16 -30
5 -99 14 -154 19 -56 6 -137 22 -190 39 -73 24 -135 34 -306 50 -118 11 -273
23 -345 26 -188 9 -433 33 -565 55 -63 10 -182 24 -265 30 -82 6 -190 16 -240
22 -64 8 -1420 12 -4680 14 -2524 2 -4617 5 -4650 7 -33 2 -88 -1 -123 -5z"/>
<path d="M17130 21579 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14
-5 0 -10 -9 -10 -21z"/>
<path d="M5498 20465 c-33 -11 -57 -28 -82 -58 -63 -77 -62 -63 -56 -700 3
-318 2 -636 -2 -705 -7 -141 1 -189 46 -261 15 -23 39 -47 54 -52 18 -7 596
-11 1842 -10 1535 0 1843 -2 1995 -15 209 -17 239 -24 288 -63 32 -25 118 -58
197 -75 14 -3 50 -28 79 -56 30 -27 59 -50 63 -50 12 0 39 -25 185 -170 74
-74 142 -148 149 -165 8 -16 19 -37 24 -45 13 -21 50 -102 50 -111 0 -3 20
-40 44 -80 51 -84 59 -114 81 -289 19 -157 19 -276 0 -427 -17 -128 -30 -179
-52 -204 -20 -23 -60 -140 -68 -196 -8 -61 -44 -173 -60 -193 -31 -36 -105
-281 -105 -347 0 -12 -5 -25 -11 -28 -15 -10 -59 -113 -69 -165 -40 -193 -51
-234 -72 -263 -38 -50 -65 -125 -78 -212 -12 -90 -24 -127 -55 -170 -22 -31
-49 -111 -60 -180 -10 -58 -44 -177 -57 -200 -31 -53 -60 -121 -67 -158 -18
-88 -45 -182 -53 -188 -22 -15 -48 -86 -81 -221 -20 -80 -45 -162 -56 -182
-28 -48 -68 -177 -80 -252 -10 -64 -33 -128 -55 -154 -16 -18 -52 -120 -61
-175 -10 -60 -45 -168 -60 -185 -28 -32 -72 -177 -90 -299 -4 -26 -20 -61 -39
-85 -25 -33 -39 -71 -65 -179 -26 -106 -41 -146 -65 -179 -18 -24 -38 -59 -45
-78 -7 -19 -22 -60 -33 -90 -12 -30 -34 -70 -50 -89 -16 -18 -35 -45 -41 -60
-19 -43 -60 -103 -93 -136 -40 -39 -74 -89 -83 -122 -4 -15 -21 -34 -40 -44
-34 -18 -81 -74 -81 -95 0 -7 -16 -21 -35 -30 -36 -17 -67 -52 -79 -90 -4 -12
-27 -30 -59 -44 -56 -26 -97 -57 -97 -74 0 -6 -42 -35 -93 -63 -50 -29 -105
-66 -120 -83 -16 -16 -35 -30 -43 -30 -28 0 -119 -43 -159 -75 -56 -44 -97
-61 -192 -79 -54 -10 -95 -24 -124 -43 -23 -15 -47 -31 -53 -34 -29 -17 -158
-40 -264 -49 -126 -9 -297 -35 -336 -50 -14 -5 -38 -5 -62 2 -33 8 -370 22
-486 19 -43 -1 -113 -33 -144 -66 -53 -57 -54 -70 -56 -578 0 -260 3 -490 7
-512 13 -68 39 -104 92 -128 22 -9 996 -13 4588 -15 2508 -2 4606 0 4662 3 91
6 108 10 147 36 82 55 81 45 84 602 2 478 2 490 -18 526 -12 20 -38 47 -58 61
-20 13 -49 34 -64 46 -26 21 -28 21 -570 22 -382 0 -562 4 -608 12 -36 7 -83
15 -105 19 -48 7 -124 46 -132 66 -3 8 -9 15 -15 15 -5 0 -26 13 -46 29 -20
16 -53 38 -72 48 -25 14 -39 32 -51 64 -8 24 -23 61 -32 81 -9 21 -17 41 -17
46 0 5 -15 23 -34 40 -44 39 -50 75 -44 261 3 133 5 140 32 179 35 51 50 94
76 221 24 117 33 144 59 172 27 29 61 124 61 172 0 46 40 170 64 196 22 25 54
117 62 179 4 32 13 74 20 96 8 21 14 49 14 61 0 12 7 28 16 36 29 24 61 103
79 194 9 50 21 112 27 139 5 27 16 56 24 64 42 42 75 130 100 272 12 71 22
101 40 120 27 29 58 112 68 185 10 64 45 177 60 190 31 27 60 131 96 334 4 24
14 49 22 55 32 24 48 67 78 211 23 115 37 159 58 190 29 41 58 131 89 269 10
44 27 91 38 105 30 37 52 98 90 246 35 137 47 175 75 240 22 49 46 134 55 195
4 25 20 66 36 91 34 54 51 107 79 249 14 67 29 114 44 134 34 45 72 139 96
234 16 66 28 91 55 119 24 25 36 48 41 79 3 24 12 49 20 55 8 6 14 18 14 25 0
8 6 14 13 14 28 0 107 125 107 168 0 11 18 34 41 53 22 18 52 54 65 80 13 27
40 61 59 76 19 15 35 34 35 40 0 12 38 53 116 125 16 15 40 39 54 55 14 15 42
39 63 53 20 14 37 30 37 36 0 11 33 37 74 59 11 5 34 30 51 55 17 25 42 50 56
57 13 6 41 19 63 30 21 10 45 28 54 41 13 21 166 102 191 102 6 0 23 16 38 35
15 19 35 35 44 35 17 0 152 46 181 61 10 5 28 20 41 33 17 17 47 27 117 40
105 20 222 56 272 85 42 24 128 39 353 59 155 14 373 16 1595 17 778 1 1472 5
1541 9 132 8 126 6 218 68 14 10 35 18 46 18 39 0 124 49 172 99 27 28 54 51
59 51 12 0 64 50 84 80 8 13 27 37 42 54 35 41 77 136 95 210 7 33 22 73 32
90 25 40 44 147 36 200 -4 22 -22 106 -41 185 -19 79 -34 151 -34 160 0 25
-45 115 -74 147 -46 53 -76 92 -76 101 0 16 -56 70 -101 98 -24 16 -50 37 -57
47 -21 29 -86 66 -139 79 -53 13 -99 34 -124 56 -27 23 -260 41 -589 45 -168
1 -3554 2 -7526 0 -3971 -1 -7254 0 -7295 3 -51 3 -89 0 -121 -10z"/>
<path d="M21180 14321 c0 -10 -31 -17 -147 -34 -45 -7 -85 -15 -89 -19 -4 -4
15 -17 42 -28 83 -36 159 -75 163 -84 8 -18 42 -46 56 -46 13 0 14 4 4 21 -16
31 -5 131 21 178 10 19 8 21 -20 21 -16 0 -30 -4 -30 -9z"/>
<path d="M13945 11920 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
